import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ChevronDownIcon } from "@heroicons/react/solid";

import { Card, Profile } from "./components";
import { GEOAPIFY_API_KEY } from "./credentials";
import { countryFlags, jobTitles } from "./data";
import { IEmployee, IMapResponse, IUser, IUserResponse } from "./interfaces";
import "./App.css";

interface IFilterValues {
    fullName: string;
    gender: "male" | "female" | "";
    nat: string | "";
}

interface IParams {
    id: string;
}

const App: FC = () => {
    const { id } = useParams<IParams>();

    const defaultFilterValues = {
        fullName: "",
        gender: "" as "",
        nat: "" as "",
    };

    const [employees, setEmployees] = useState<IEmployee[]>([]);
    const [activeEmployee, setActiveEmployee] = useState<IEmployee | null>(
        null
    );
    const [filterValues, setFilterValues] =
        useState<IFilterValues>(defaultFilterValues);

    useEffect(() => {
        const fetchUser = async () => {
            const seed = "employees";
            const count = 40;

            // Fetch user data by calling RandomUser API
            try {
                const userRequest = await fetch(
                    `https://randomuser.me/api/?seed=${seed}&results=${count}&noinfo`
                );

                const { results: users } =
                    (await userRequest.json()) as IUserResponse;

                const coverImages = await Promise.all(
                    users.map((u) => getCoverImage(u))
                );

                const employees = users.map((u, i) => ({
                    ...u,
                    fullName: `${u.name.title} ${u.name.first} ${u.name.last}`,
                    coverImage: coverImages[i],
                    jobTitle: jobTitles[i],
                }));

                setEmployees(employees);
            } catch (e) {
                console.log(e);
            }
        };

        fetchUser();
    }, []);

    useEffect(() => {
        const e = employees.find(({ login }) => login.username === id) || null;

        setActiveEmployee(e);
    }, [employees, id]);

    const getCoverImage = async (user: IUser): Promise<HTMLImageElement> => {
        const coverImage = new Image();

        if (!user) {
            return coverImage;
        }

        try {
            const { city, state, country, postcode } = user.location;

            const endpoint = "https://maps.geoapify.com/v1";

            const locationRequest = await fetch(
                `${endpoint}/geocode/search?text=${city},${state},${country},${postcode}&apiKey=${GEOAPIFY_API_KEY}`
            );

            const { features } = (await locationRequest.json()) as IMapResponse;
            const [latitude, longitude] = features[0].geometry.coordinates;

            const coverImageUrl = `https://maps.geoapify.com/v1/staticmap?style=osm-bright-smooth&width=600&height=400&center=lonlat:${latitude},${longitude}&zoom=14&apiKey=${GEOAPIFY_API_KEY}`;
            coverImage.src = coverImageUrl;

            return coverImage;
        } catch (error) {
            console.log(error);
            return coverImage;
        }
    };

    const updateFilter = (
        e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        e.preventDefault();

        const { value, name } = e.target;

        setFilterValues({
            ...filterValues,
            [name]: value,
        });
    };

    const resetFilters = () => {
        setFilterValues(defaultFilterValues);
    };

    const filteredEmployees = employees?.filter(
        ({ fullName, gender, nat }) =>
            (filterValues.fullName === "" ||
                fullName.toLowerCase().includes(filterValues.fullName)) &&
            (filterValues.gender === "" || gender === filterValues.gender) &&
            (filterValues.nat === "" || nat === filterValues.nat)
    );

    return (
        <div className='flex flex-col mx-auto min-h-screen'>
            <div className='bg-gray-800'>
                <div className='max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8'>
                    <div className='text-center'>
                        <p className='text-4xl font-extrabold text-white sm:text-5xl sm:tracking-tight lg:text-6xl'>
                            Example Employee Directory
                        </p>
                        <p className='max-w-md mt-5 mx-auto text-xl text-gray-400'>
                            Search and filter this directory of our
                            (non-existent) employees. Click on a profile to see
                            more about them!
                        </p>
                    </div>

                    <div className='mt-10 grid gap-4 grid-cols-1 md:grid-cols-3'>
                        <div>
                            <label
                                htmlFor='fullName'
                                className='block text-base font-medium text-gray-300'>
                                Name
                            </label>
                            <div className='mt-1.5 relative'>
                                <input
                                    id='fullName'
                                    name='fullName'
                                    className='appearance-none block w-full bg-none bg-gray-700 border border-transparent rounded-md pl-3 pr-10 py-2 text-base text-white focus:outline-none focus:ring-1 focus:ring-white focus:border-white sm:text-sm'
                                    value={filterValues.fullName}
                                    onChange={updateFilter}
                                />
                            </div>
                        </div>
                        <div>
                            <label
                                htmlFor='gender'
                                className='block text-base font-medium text-gray-300'>
                                Gender
                            </label>
                            <div className='mt-1.5 relative'>
                                <select
                                    id='gender'
                                    name='gender'
                                    className='appearance-none block w-full bg-none bg-gray-700 border border-transparent rounded-md pl-3 pr-10 py-2 text-base text-white focus:outline-none focus:ring-1 focus:ring-white focus:border-white sm:text-sm'
                                    value={filterValues.gender}
                                    onChange={updateFilter}>
                                    <option value=''>All</option>
                                    <option value='male'>Male</option>
                                    <option value='female'>Female</option>
                                </select>
                                <div className='pointer-events-none absolute inset-y-0 right-0 px-2 flex items-center'>
                                    <ChevronDownIcon
                                        className='h-4 w-4 text-white'
                                        aria-hidden='true'
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <label
                                htmlFor='nat'
                                className='block text-base font-medium text-gray-300'>
                                Nationality
                            </label>
                            <div className='mt-1.5 relative'>
                                <select
                                    id='nat'
                                    name='nat'
                                    className='appearance-none block w-full bg-none bg-gray-700 border border-transparent rounded-md pl-3 pr-10 py-2 text-base text-white focus:outline-none focus:ring-1 focus:ring-white focus:border-white sm:text-sm'
                                    value={filterValues.nat}
                                    onChange={updateFilter}>
                                    <option value=''>All</option>
                                    {Object.keys(countryFlags).map((c) => (
                                        <option key={c} value={c}>
                                            {c} — {countryFlags[c]}
                                        </option>
                                    ))}
                                </select>
                                <div className='pointer-events-none absolute inset-y-0 right-0 px-2 flex items-center'>
                                    <ChevronDownIcon
                                        className='h-4 w-4 text-white'
                                        aria-hidden='true'
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='md:col-start-2 col-span-1'>
                            <button
                                className='appearance-none block w-full bg-none bg-gray-700 border border-transparent rounded-md pl-3 pr-10 py-2 text-base text-white focus:outline-none focus:ring-1 focus:ring-white focus:border-white sm:text-sm'
                                onClick={resetFilters}>
                                Reset filters
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <Profile employee={activeEmployee} />

            <div className={`flex max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8 items-center ${employees.length === 0 && 'flex-grow'}`}>
                {employees.length === 0 && (
                    <div className='flex'>
                        <div className='h-5 w-5 bg-gray-800 rounded-full animate-bounce'></div>
                        <div className='h-5 w-5 bg-gray-800 rounded-full ml-1 animate-bounce200'></div>
                        <div className='h-5 w-5 bg-gray-800 rounded-full ml-1 animate-bounce400'></div>
                    </div>
                )}

                <ul className='grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'>
                    {filteredEmployees.map((e) => (
                        <Card key={e.login.username} {...e} />
                    ))}

                    {employees.length !== 0 && filteredEmployees.length === 0 && (
                        <div className='col-span-full'>
                            <h2 className='text-lg'>
                                There aren't any results which match your
                                filters.
                            </h2>
                        </div>
                    )}
                </ul>
            </div>
        </div>
    );
};

export default App;
