export const countryFlags: { [key: string]: string } = {
    AU: "🇦🇺",
    BR: "🇧🇷",
    CA: "🇨🇦",
    CH: "🇨🇭",
    DE: "🇩🇪",
    DK: "🇩🇰",
    ES: "🇪🇸",
    FI: "🇫🇮",
    FR: "🇫🇷",
    GB: "🇬🇧",
    IE: "🇮🇪",
    IR: "🇮🇷",
    NO: "🇳🇴",
    NL: "🇳🇱",
    NZ: "🇳🇿",
    TR: "🇹🇷",
    US: "🇺🇸",
};

export const jobTitles = [
    "Mining Planner",
    "Marketing Representative",
    "National Agent",
    "Advertising Manager",
    "Consultant",
    "Dynamic Planner",
    "Banking Designer",
    "Future Design Supervisor",
    "Investor Education Engineer",
    "Technology Engineer",
    "Legal Producer",
    "Administration Designer",
    "Real-Estate Coordinator",
    "Accounting Strategist",
    "Forward Representative",
    "Legal Coordinator",
    "Advertising Representative",
    "Head of Customer Acquisition",
    "Retail Administrator",
    "Central Administrator",
    "Legacy Engineer",
    "Product Liaison",
    "Construction Designer",
    "District Real-Estate Executive",
    "Lead Sales Specialist",
    "Marketing Liaison",
    "Accounting Consultant",
    "Legal Director",
    "Direct Assistant",
    "Mining Assistant",
    "Marketing Facilitator",
    "Education Architect",
    "IT Assistant",
    "Community Services Architect",
    "Real-Estate Developer",
    "Investor Producer",
    "Senior Developer",
    "Direct Accounting Engineer",
    "Legal Representative",
    "Chief Technology Planner",
];
