import React, { FC } from "react";
import { MailIcon, PhoneIcon } from "@heroicons/react/solid";

import { IEmployee } from "../interfaces";
import { countryFlags } from "../data";
import { Link } from "react-router-dom";

export const Card: FC<IEmployee> = ({
    login: { username },
    picture: { large },
    fullName,
    jobTitle,
    email,
    phone,
    nat,
}) => (
    <li className='col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200'>
        <Link to={`/${username}`}>
            <div className='flex-1 flex flex-col p-8'>
                <img
                    className='w-32 h-32 flex-shrink-0 mx-auto bg-black rounded-full'
                    src={large}
                    alt={fullName}
                />
                <h3 className='mt-6 text-gray-900 text-lg font-medium'>
                    {fullName}
                </h3>
                <dl className='mt-1 flex-grow flex flex-col justify-between'>
                    <dd>
                        <span className='px-2 py-1 text-green-800 text-sm font-medium bg-green-100 rounded-full'>
                            {jobTitle} {countryFlags[nat]}
                        </span>
                    </dd>
                </dl>
            </div>
        </Link>
        <div>
            <div className='-mt-px flex divide-x divide-gray-200'>
                <div className='w-0 flex-1 flex'>
                    <a
                        href={`mailto:${email}`}
                        className='relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500'>
                        <MailIcon
                            className='w-5 h-5 text-gray-400'
                            aria-hidden='true'
                        />
                        <span className='ml-3'>Email</span>
                    </a>
                </div>
                <div className='-ml-px w-0 flex-1 flex'>
                    <a
                        href={`tel:${phone}`}
                        className='relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500'>
                        <PhoneIcon
                            className='w-5 h-5 text-gray-400'
                            aria-hidden='true'
                        />
                        <span className='ml-3'>Call</span>
                    </a>
                </div>
            </div>
        </div>
    </li>
);
