import React, { FC, Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { useHistory } from "react-router-dom";

import { countryFlags } from "../data";
import { IEmployee } from "../interfaces";

interface IProfileProps {
    employee: IEmployee | null;
}

export const Profile: FC<IProfileProps> = ({ employee }) => {
    const history = useHistory();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (!!employee) {
            setOpen(true);
        }
    }, [employee]);

    const {
        fullName,
        picture,
        coverImage,
        jobTitle,
        nat,
        email,
        phone,
        location,
    } = employee || {};

    const closeModal = () => {
        setOpen(false);

        setTimeout(() => history.push("/"), 200);
    };

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as='div'
                static
                className='fixed z-10 inset-0 overflow-y-auto'
                open={open}
                onClose={closeModal}>
                <div className='flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'>
                        <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className='hidden sm:inline-block sm:align-middle sm:h-screen'
                        aria-hidden='true'>
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
                        <div className='inline-block align-bottom bg-white rounded-lg pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full'>
                            <div className='block absolute top-0 right-0 pt-4 pr-4'>
                                <button
                                    type='button'
                                    className='bg-transparent rounded-md text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200'
                                    onClick={closeModal}>
                                    <span className='sr-only'>Close</span>
                                    <XIcon
                                        className='h-6 w-6'
                                        aria-hidden='true'
                                    />
                                </button>
                            </div>

                            <div>
                                {employee && (
                                    <div>
                                        <img
                                            className='h-32 w-full object-cover lg:h-48'
                                            src={coverImage?.src}
                                            alt={`Map of ${fullName}'s address`}
                                        />
                                    </div>
                                )}
                                <div className='max-w-5xl mx-auto px-4 sm:px-6 lg:px-8'>
                                    <div className='-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5'>
                                        <div className='flex'>
                                            <img
                                                className='h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32'
                                                src={picture?.large}
                                                alt=''
                                            />
                                        </div>
                                        <div className='mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1'>
                                            <div className='sm:hidden 2xl:block mt-6 min-w-0 flex-1'>
                                                <h1 className='mb-1 text-2xl font-bold text-gray-900 truncate'>
                                                    {fullName}
                                                </h1>
                                                <span className='px-2 py-1 text-green-800 text-sm font-medium bg-green-100 rounded-full'>
                                                    {jobTitle}{" "}
                                                    {countryFlags[nat ?? ""]}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='hidden sm:block 2xl:hidden mt-6 min-w-0 flex-1'>
                                        <h1 className='mb-1 text-2xl font-bold text-gray-900 truncate'>
                                            {fullName}
                                        </h1>
                                        <span className='px-2 py-1 text-green-800 text-sm font-medium bg-green-100 rounded-full'>
                                            {jobTitle} {countryFlags[nat ?? ""]}
                                        </span>
                                    </div>
                                </div>
                                <div className='mt-6 max-w-3xl mx-auto grid grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense'>
                                    <div className='space-y-6 lg:col-start-1 lg:col-span-2'>
                                        <section>
                                            <div className='border-t border-gray-200 px-4 py-5 sm:px-6'>
                                                <dl className='grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2'>
                                                    <div className='sm:col-span-1'>
                                                        <dt className='text-sm font-medium text-gray-500'>
                                                            Email address
                                                        </dt>
                                                        <dd className='mt-1 text-sm text-gray-900'>
                                                            <a
                                                                className='hover:underline'
                                                                href={`mailto:${email}`}>
                                                                {email}
                                                            </a>
                                                        </dd>
                                                    </div>
                                                    <div className='sm:col-span-1'>
                                                        <dt className='text-sm font-medium text-gray-500'>
                                                            Phone number
                                                        </dt>
                                                        <dd className='mt-1 text-sm text-gray-900'>
                                                            <a
                                                                className='hover:underline'
                                                                href={`tel:${phone}`}>
                                                                {phone}
                                                            </a>
                                                        </dd>
                                                    </div>
                                                    <div className='sm:col-span-1'>
                                                        <dt className='text-sm font-medium text-gray-500'>
                                                            Time zone
                                                        </dt>
                                                        <dd className='mt-1 text-sm text-gray-900'>
                                                            {
                                                                location
                                                                    ?.timezone
                                                                    .description
                                                            }{" "}
                                                            <span className='text-gray-500'>
                                                                (
                                                                {
                                                                    location
                                                                        ?.timezone
                                                                        .offset
                                                                }
                                                                )
                                                            </span>
                                                        </dd>
                                                    </div>
                                                    <div className='sm:col-span-1'>
                                                        <dt className='text-sm font-medium text-gray-500'>
                                                            Location
                                                        </dt>
                                                        <dd className='mt-1 text-sm text-gray-900'>
                                                            {location?.city},{" "}
                                                            {location?.state},{" "}
                                                            {location?.country}
                                                        </dd>
                                                    </div>
                                                </dl>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
};
